nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: var(--color-background-light);
    font-weight: bold;
    line-height: 1;
    font-size: 0.75rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
    z-index: 100;
    height: calc(4rem + env(safe-area-inset-bottom));
    display: flex;
    padding-bottom: env(safe-area-inset-bottom);

    ul.menu {
        margin: 0;
        width: 100vw;
        display: flex;

        li {
            list-style: none;
            padding: 0;
            flex: 3;
            display: flex;
            flex-direction: column;

            a, button {
                text-decoration: none;
                color: var(--color-text);
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.625em 0;
                height: 100%;
                justify-content: end;

                svg {
                    margin-bottom: 0.5em;
                    width: 1.75rem;
                    height: 1.72rem;
                }


                &:hover,
                &:focus,
                &.is-active {
                    color: var(--color-primary);
                }
            }
        }
    }
}
