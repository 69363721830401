.auth-container{
    .logo{
        display: flex;
        justify-content: center;
        margin: 4rem 0 2rem;

        svg{
            width: 25%;
        }
    }
}

body.auth{
    background: url('/assets/images/bg-auth.jpg') no-repeat center bottom fixed;
    background-size: cover;

    &:after{
        content: none;
    }
}
