* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html, body {
    overflow-x: hidden;
}

body{

}

h1, h2, h3, h4, h5, h6, blockquote {
    margin: 0;
}

ul {
    margin: 0;

    li {
        padding: 0;
    }
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}

figure {
    margin: 0;
}

button {
    appearance: none;
    background: none;
    padding: 0;
    outline: none;
    border: 0;
    cursor: pointer;
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: $font-weight-base;
}

:focus {
    outline: none;
}

:focus-visible {
    @include focus-box-shadow(var(--color-primary));
}

#drupal-live-announce {
    height: 0;
}
