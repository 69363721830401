header {
    height: 30vh;
    position: relative;
    z-index: 1;
   mask-image: linear-gradient(to top, transparent 0%, black 15%);

    > img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 1) 100%);
        height: 40vh;
    }

    & ~ .container {
        position: relative;
        z-index: 1;
    }

    &.no-overflow{
        mask-image: none;
        height: auto;
        > img{
            height: auto;
            aspect-ratio: 16/9;
            max-height: 30vh;
        }
        &:after{
            content: none;
        }
    }

    @include media-breakpoint-up(lg){
        > img {
            height: 60vh;
        }
    }
}
