html, body {
    overflow-x: hidden;
    background-color: #0D2215;
}

body{
    min-height: 100vh;
    background: linear-gradient(180deg, #0D2215 0%, var(--color-black) 99.78%) fixed;
    padding-bottom: calc(6rem + env(safe-area-inset-bottom));

    &:after{
        content: "";
        position: fixed;
        bottom: 0;
        height: 70%;
        width: 50%;
        left: 65%;
        background: url('/assets/images/polygon.svg') no-repeat center right;
        z-index: 0;
        pointer-events: none;
    }

    > div{
        position: relative;
        z-index: 10;
    }
}

hr{
    border-color: var(--color-primary);
}

#app > div > .container{
    max-width: 720px;
}
