.room {
    padding-bottom: calc(9rem + env(safe-area-inset-bottom));

    footer {
        position: fixed;
        bottom: calc(4rem + env(safe-area-inset-bottom));
        background: var(--color-black-light);
        left: 0;
        width: 100vw;
        height: 4rem;
        display: flex;
        align-items: center;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
        justify-content: center;
        z-index: 50;
    }
}
