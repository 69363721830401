.card{
    background: var(--color-background-light);
    border-radius: $border-radius;
    padding: 1rem;

    img, svg.placeholder{
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: $border-radius;
        object-fit: cover;
    }

    .virtual-h1{
        line-height: 1;
    }

    &.background-primary{
        background-color: var(--color-primary);
    }

    &.bordered{
        background: alpha(var(--color-primary), 0.4);
        border: 1px solid var(--color-primary);
    }

}
