/**
 * Put all variables here that are necessary for this project
 * Group colors by starting their names with "color-"
 */

// Defines CSS variable as HSL color so it can be used with opacity
@mixin defineColorHSL($color, $hue, $saturation, $lightness){
    #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");#{$color}-h: #{$hue};#{$color}-s: #{$saturation};#{$color}-l: #{$lightness};
}

:root {
    @include defineColorHSL(--color-primary, 143, 56%, 40%);
    @include defineColorHSL(--color-secondary, 196, 100%, 9%);
    @include defineColorHSL(--color-text, 0, 0%, 100%);
    @include defineColorHSL(--color-black, 0, 0%, 1%);
    @include defineColorHSL(--color-black-light, 240, 4%, 14%);
    @include defineColorHSL(--color-white, 0, 0%, 100%);
    @include defineColorHSL(--color-background, 0, 0%, 1%);
    @include defineColorHSL(--color-background-light, 146, 8%, 18%);
    @include defineColorHSL(--color-grey, 143, 8%, 55%);

    @include defineColorHSL(--color-danger, 0, 79%, 63%);
    @include defineColorHSL(--color-warning, 29, 100%, 50%);
    @include defineColorHSL(--color-valid, 145, 63%, 42%);

    --color-primary-l-darker:       0.8;
    --color-secondary-l-lighter:    3;
}

// Font weights
$font-weight-normal:    400;
$font-weight-bold:      700;
$font-weight-medium:      500;
$font-weight-base:      $font-weight-medium;

// Fonts
$font-family-base:    'Rajdhani', sans-serif;
$font-family-titles:  $font-family-base;
$line-height-base:    1.5;

// Bootstrap grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1380px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

// Margins/Paddings
$paragraph-margin-bottom: 2rem;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer * .25),
            2: ($spacer * .5),
            3: $spacer,
            4: ($spacer * 1.5),
            5: ($spacer * 2),
            6: ($spacer * 3),
            7: ($spacer * 4),
            8: ($spacer * 4.5)
        ),
        $spacers
);
$grid-gutter-width: 4rem;

// Border radius
$border-radius: 0.625rem;

// Transitions
$transition-hover: all 0.1s ease-in-out;

// Button styling
$btn-font-size:     1.125rem;
$btn-font-family:   $font-family-titles;
$btn-font-weight:   $font-weight-bold;
$btn-line-height:   1.272;
$btn-padding-y:     0.67em;
$btn-padding-x:     1.333em;
$btn-border-radius: 5px;
$btn-transition:    color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Input styling
$input-padding-y:           0.75rem;
$input-padding-x:           1rem;
$input-border-width:        1px;
$input-height-border:       $input-border-width * 2;
$input-height:              3.25rem;
$input-line-height:         $line-height-base;
$input-border-color:        var(--color-white);
$input-border-color-focus:  lightness(var(--color-black), 0);
$input-border-radius:       0.3rem;
$input-font-size:           1em;
$input-color:               var(--color-black);
$input-placeholder-color:   #AAAAAA;
$input-bg:                  var(--color-white);
$checkbox-size:             1.25rem;
$checkbox-padding:          calc(#{$checkbox-size} + 0.5em);
$radio-size:                1.25rem;
$radio-padding:             calc(#{$radio-size} + 0.5em);
$form-switch-width:         3.625em;
$form-check-input-width:    2em;
$form-check-input-checked-bg-color: var(--color-valid);
$form-check-input-border:   1px solid $form-check-input-checked-bg-color;
// $form-switch-bg-image:      url('../images/form/switch.svg');
