/**
 * Put all variables here that are necessary for this project
 * Group colors by starting their names with "color-"
 */
:root {
  --color-primary: hsl(143, 56%, 40%);
  --color-primary-h: 143;
  --color-primary-s: 56%;
  --color-primary-l: 40%;
  --color-secondary: hsl(196, 100%, 9%);
  --color-secondary-h: 196;
  --color-secondary-s: 100%;
  --color-secondary-l: 9%;
  --color-text: hsl(0, 0%, 100%);
  --color-text-h: 0;
  --color-text-s: 0%;
  --color-text-l: 100%;
  --color-black: hsl(0, 0%, 1%);
  --color-black-h: 0;
  --color-black-s: 0%;
  --color-black-l: 1%;
  --color-black-light: hsl(240, 4%, 14%);
  --color-black-light-h: 240;
  --color-black-light-s: 4%;
  --color-black-light-l: 14%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-background: hsl(0, 0%, 1%);
  --color-background-h: 0;
  --color-background-s: 0%;
  --color-background-l: 1%;
  --color-background-light: hsl(146, 8%, 18%);
  --color-background-light-h: 146;
  --color-background-light-s: 8%;
  --color-background-light-l: 18%;
  --color-grey: hsl(143, 8%, 55%);
  --color-grey-h: 143;
  --color-grey-s: 8%;
  --color-grey-l: 55%;
  --color-danger: hsl(0, 79%, 63%);
  --color-danger-h: 0;
  --color-danger-s: 79%;
  --color-danger-l: 63%;
  --color-warning: hsl(29, 100%, 50%);
  --color-warning-h: 29;
  --color-warning-s: 100%;
  --color-warning-l: 50%;
  --color-valid: hsl(145, 63%, 42%);
  --color-valid-h: 145;
  --color-valid-s: 63%;
  --color-valid-l: 42%;
  --color-primary-l-darker: 0.8;
  --color-secondary-l-lighter: 3;
}

/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Rajdhani, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 2rem);
  padding-left: var(--bs-gutter-x, 2rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1380px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 3rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 3rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 4.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 3rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 3rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 4rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 4rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1380px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 4.5rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 3rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 3rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 3rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 3rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 4.5rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 3rem !important;
  }

  .m-sm-7 {
    margin: 4rem !important;
  }

  .m-sm-8 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 3rem !important;
  }

  .mt-sm-7 {
    margin-top: 4rem !important;
  }

  .mt-sm-8 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 3rem !important;
  }

  .me-sm-7 {
    margin-right: 4rem !important;
  }

  .me-sm-8 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 3rem !important;
  }

  .ms-sm-7 {
    margin-left: 4rem !important;
  }

  .ms-sm-8 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 3rem !important;
  }

  .p-sm-7 {
    padding: 4rem !important;
  }

  .p-sm-8 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 3rem !important;
  }

  .pt-sm-7 {
    padding-top: 4rem !important;
  }

  .pt-sm-8 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 3rem !important;
  }

  .pe-sm-7 {
    padding-right: 4rem !important;
  }

  .pe-sm-8 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 3rem !important;
  }

  .ps-sm-7 {
    padding-left: 4rem !important;
  }

  .ps-sm-8 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 3rem !important;
  }

  .m-md-7 {
    margin: 4rem !important;
  }

  .m-md-8 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 3rem !important;
  }

  .mt-md-7 {
    margin-top: 4rem !important;
  }

  .mt-md-8 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 3rem !important;
  }

  .me-md-7 {
    margin-right: 4rem !important;
  }

  .me-md-8 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4rem !important;
  }

  .mb-md-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 3rem !important;
  }

  .ms-md-7 {
    margin-left: 4rem !important;
  }

  .ms-md-8 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 3rem !important;
  }

  .p-md-7 {
    padding: 4rem !important;
  }

  .p-md-8 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 3rem !important;
  }

  .pt-md-7 {
    padding-top: 4rem !important;
  }

  .pt-md-8 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 3rem !important;
  }

  .pe-md-7 {
    padding-right: 4rem !important;
  }

  .pe-md-8 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 3rem !important;
  }

  .pb-md-7 {
    padding-bottom: 4rem !important;
  }

  .pb-md-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 3rem !important;
  }

  .ps-md-7 {
    padding-left: 4rem !important;
  }

  .ps-md-8 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 3rem !important;
  }

  .m-lg-7 {
    margin: 4rem !important;
  }

  .m-lg-8 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 3rem !important;
  }

  .mt-lg-7 {
    margin-top: 4rem !important;
  }

  .mt-lg-8 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 3rem !important;
  }

  .me-lg-7 {
    margin-right: 4rem !important;
  }

  .me-lg-8 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 3rem !important;
  }

  .ms-lg-7 {
    margin-left: 4rem !important;
  }

  .ms-lg-8 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 3rem !important;
  }

  .p-lg-7 {
    padding: 4rem !important;
  }

  .p-lg-8 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 3rem !important;
  }

  .pt-lg-7 {
    padding-top: 4rem !important;
  }

  .pt-lg-8 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 3rem !important;
  }

  .pe-lg-7 {
    padding-right: 4rem !important;
  }

  .pe-lg-8 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 3rem !important;
  }

  .ps-lg-7 {
    padding-left: 4rem !important;
  }

  .ps-lg-8 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 3rem !important;
  }

  .m-xl-7 {
    margin: 4rem !important;
  }

  .m-xl-8 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 3rem !important;
  }

  .mt-xl-7 {
    margin-top: 4rem !important;
  }

  .mt-xl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 3rem !important;
  }

  .me-xl-7 {
    margin-right: 4rem !important;
  }

  .me-xl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 3rem !important;
  }

  .ms-xl-7 {
    margin-left: 4rem !important;
  }

  .ms-xl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 3rem !important;
  }

  .p-xl-7 {
    padding: 4rem !important;
  }

  .p-xl-8 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 3rem !important;
  }

  .pt-xl-7 {
    padding-top: 4rem !important;
  }

  .pt-xl-8 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 3rem !important;
  }

  .pe-xl-7 {
    padding-right: 4rem !important;
  }

  .pe-xl-8 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 3rem !important;
  }

  .ps-xl-7 {
    padding-left: 4rem !important;
  }

  .ps-xl-8 {
    padding-left: 4.5rem !important;
  }
}
@media (min-width: 1380px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 3rem !important;
  }

  .m-xxl-7 {
    margin: 4rem !important;
  }

  .m-xxl-8 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-6 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-8 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-8 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 3rem !important;
  }

  .mt-xxl-7 {
    margin-top: 4rem !important;
  }

  .mt-xxl-8 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 3rem !important;
  }

  .me-xxl-7 {
    margin-right: 4rem !important;
  }

  .me-xxl-8 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 3rem !important;
  }

  .ms-xxl-7 {
    margin-left: 4rem !important;
  }

  .ms-xxl-8 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 3rem !important;
  }

  .p-xxl-7 {
    padding: 4rem !important;
  }

  .p-xxl-8 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-6 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-8 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-8 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 3rem !important;
  }

  .pt-xxl-7 {
    padding-top: 4rem !important;
  }

  .pt-xxl-8 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 3rem !important;
  }

  .pe-xxl-7 {
    padding-right: 4rem !important;
  }

  .pe-xxl-8 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 3rem !important;
  }

  .ps-xxl-7 {
    padding-left: 4rem !important;
  }

  .ps-xxl-8 {
    padding-left: 4.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*
    Element centering
*/
/*
    Overlays and backgrounds
*/
/*
    Accessibility
*/
/*
    Spacing
*/
/*
    Extra
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, blockquote {
  margin: 0;
}

ul {
  margin: 0;
}
ul li {
  padding: 0;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}

figure {
  margin: 0;
}

button {
  appearance: none;
  background: none;
  padding: 0;
  outline: none;
  border: 0;
  cursor: pointer;
  font-family: "Rajdhani", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

:focus {
  outline: none;
}

:focus-visible {
  box-shadow: 0 0 0 0.25rem hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}

#drupal-live-announce {
  height: 0;
}

body {
  font-weight: 500;
  font-size: 1rem;
  color: var(--color-text);
  font-family: "Rajdhani", sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
}

.virtual-h0 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.285;
  font-size: 2.75rem;
}
@media (min-width: 576px) {
  .virtual-h0 {
    font-size: 3.5rem;
    font-size: clamp(2rem, calc(5.5vw + 1rem), 3.5rem);
  }
}

h1,
.virtual-h1 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.285;
  font-size: 1.75rem;
  margin-bottom: 0.57em;
}
@media (min-width: 576px) {
  h1,
.virtual-h1 {
    font-size: 3.5rem;
    font-size: clamp(2rem, calc(5.5vw + 1rem), 3.5rem);
  }
}

h2,
.virtual-h2 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.3;
  margin-bottom: 1em;
}
@media (min-width: 576px) {
  h2,
.virtual-h2 {
    font-size: 2rem;
  }
}

h3,
.virtual-h3 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.38;
  margin-bottom: 1em;
}
@media (min-width: 576px) {
  h3,
.virtual-h3 {
    font-size: 1.625rem;
  }
}

h4,
.virtual-h4 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5em;
}
@media (min-width: 576px) {
  h4,
.virtual-h4 {
    font-size: 1.125rem;
  }
}

.subtitle {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-primary);
  margin-bottom: 1em;
}
@media (min-width: 576px) {
  .subtitle {
    font-size: 1.125rem;
  }
}

.intro-text {
  font-weight: 700;
}

p {
  margin-bottom: 2rem;
}

a, button {
  color: var(--color-text);
  text-decoration: underline;
  transition: all 0.1s ease-in-out;
}
a:hover, a:focus, button:hover, button:focus {
  color: var(--color-primary-darker);
}

blockquote {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.285;
  font-weight: 700;
  margin: 0 0 0.57em 0;
}
@media (min-width: 576px) {
  blockquote {
    font-size: 3.5rem;
    font-size: clamp(2rem, calc(5.5vw + 1rem), 3.5rem);
  }
}
blockquote p {
  margin-bottom: 0;
}
blockquote p:before {
  content: open-quote;
  display: inline;
}
blockquote p:after {
  content: close-quote;
  display: inline;
}

.text-right {
  text-align: right;
}

.text-light {
  color: hsla(var(--color-text-h), var(--color-text-s), var(--color-text-l), 0.5);
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-seconday);
}

.text-slightly-smaller {
  font-size: 0.875rem;
}

.text-slightly-larger {
  font-size: 1.125rem;
}

.icon-text {
  display: flex;
  flex-direction: row;
}
.icon-text > svg {
  margin-top: 0.125rem;
  margin-right: 0.5rem;
}
.icon-text.small > svg {
  margin-right: 0.125rem;
}

.text-icon {
  display: flex;
  flex-direction: row;
}
.text-icon > svg {
  margin-top: 0.125rem;
  margin-left: 0.5rem;
}
.text-icon.small > svg {
  margin-left: 0.125rem;
}

ol {
  padding-left: 1rem;
  counter-reset: item;
}

ol li {
  display: block;
  position: relative;
}
ol li.no-before:before {
  visibility: hidden;
}

ol li:before {
  content: counters(item, ".");
  counter-increment: item;
  position: absolute;
  margin-right: 100%;
  right: 10px;
  /* space between number and text */
}

@media (prefers-reduced-motion: reduce) {
  *,
*::before,
*::after {
    animation-duration: 0.1ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.col-grow--left > div {
  float: right;
}
.col-grow > div {
  display: flex;
  height: 100%;
  width: calc(100% + 2rem);
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .col-grow > div {
    width: calc(100% + 4rem/3 + ((100vw - 540px) / 2));
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .col-grow > div {
    width: calc(100% + 4rem/3 + ((100vw - 720px) / 2));
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .col-grow > div {
    width: calc(100% + 4rem/3 + ((100vw - 960px) / 2));
  }
}
@media (min-width: 1200px) and (max-width: 1379.98px) {
  .col-grow > div {
    width: calc(100% + 4rem/3 + ((100vw - 1140px) / 2));
  }
}
@media (min-width: 1380px) {
  .col-grow > div {
    width: calc(100% + 4rem/3 + ((100vw - 1320px) / 2));
  }
}

/*
    Accessibility
*/
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

/*
    Spacing
*/
.section-spacing-padding {
  padding-block: 2rem;
}
@media (min-width: 768px) {
  .section-spacing-padding {
    padding-block: 6rem;
  }
}

.section-spacing-margin {
  margin-block: 4rem;
}
@media (min-width: 768px) {
  .section-spacing-margin {
    margin-block: 8rem;
  }
}

/*
    Extra
*/
.w-100 {
  width: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.btn-primary {
  display: inline-flex;
  position: relative;
  color: var(--color-white);
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1.272;
  padding: 0.67em 1.333em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  text-decoration: none;
  outline-offset: 4px;
  background: var(--color-primary);
}
.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus {
  color: var(--color-white);
}
.btn-primary:disabled {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-primary > svg {
  margin-right: 0.25rem;
}
.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus {
  border-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
.btn-primary:not(:disabled):focus-visible {
  outline-color: var(--color-primary);
}
.btn-primary.outline {
  padding: calc(0.67em - 1px) calc(1.333em - 1px);
  background: transparent;
  border: 2px solid var(--color-primary);
}

.btn-secondary {
  display: inline-flex;
  position: relative;
  color: var(--color-white);
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1.272;
  padding: 0.67em 1.333em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  text-decoration: none;
  outline-offset: 4px;
  background: var(--color-black-light);
}
.btn-secondary:not(:disabled):hover, .btn-secondary:not(:disabled):focus {
  color: var(--color-white);
}
.btn-secondary:disabled {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-secondary > svg {
  margin-right: 0.25rem;
}
.btn-secondary:not(:disabled):hover, .btn-secondary:not(:disabled):focus {
  border-color: hsl(var(--color-black-light-h), var(--color-black-light-s), calc(var(--color-black-light-l) * var(--color-black-light-l-lighter)));
}
.btn-secondary:not(:disabled):focus-visible {
  outline-color: var(--color-black-light);
}

.btn-outline-inactive {
  display: inline-flex;
  position: relative;
  color: var(--color-white);
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1.272;
  padding: 0.67em 1.333em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  text-decoration: none;
  outline-offset: 4px;
  color: var(--color-white);
  background: hsla(var(--color-black-light-h), var(--color-black-light-s), var(--color-black-light-l), 0.8);
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-outline-inactive:not(:disabled):hover, .btn-outline-inactive:not(:disabled):focus {
  color: var(--color-white);
}
.btn-outline-inactive:disabled {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-outline-inactive > svg {
  margin-right: 0.25rem;
}
.btn-outline-inactive:not(:disabled):hover, .btn-outline-inactive:not(:disabled):focus {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-outline-inactive:not(:disabled):focus-visible {
  outline-color: var(--color-primary);
}

.btn-outline {
  display: inline-flex;
  position: relative;
  color: var(--color-white);
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1.272;
  padding: 0.67em 1.333em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  text-decoration: none;
  outline-offset: 4px;
  color: var(--color-white);
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.4);
  border-color: var(--color-primary);
}
.btn-outline:not(:disabled):hover, .btn-outline:not(:disabled):focus {
  color: var(--color-white);
}
.btn-outline:disabled {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
.btn-outline > svg {
  margin-right: 0.25rem;
}
.btn-outline:not(:disabled):hover, .btn-outline:not(:disabled):focus {
  color: var(--color-white);
  border-color: var(--color-primary);
}
.btn-outline:not(:disabled):focus-visible {
  outline-color: var(--color-primary);
}
.btn-outline:disabled {
  color: var(--color-grey);
  background: var(--color-black-light);
  border-color: var(--color-grey);
  opacity: 0.5;
}

.text-link {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration: none;
  outline-offset: 4px;
  box-shadow: none;
}
.text-link:not(:disabled):hover, .text-link:not(:disabled):focus {
  color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
.text-link:not(:disabled):hover:after, .text-link:not(:disabled):focus:after {
  background-color: currentColor;
}
.text-link:not(:disabled):focus-visible {
  outline: 1px dashed var(--color-primary);
}
.text-link:after {
  content: "";
  mask: url("/assets/icons/arrow.svg") no-repeat 50% 50%;
  mask-size: contain;
  mask-position: right;
  display: inline-block;
  height: 0.66em;
  width: 0.66em;
  background-color: var(--color-primary);
  transition: all 0.1s ease-in-out;
  margin-left: 0.66em;
}

button, a {
  -webkit-tap-highlight-color: transparent;
}

.btn-loader::after {
  box-sizing: border-box;
  content: "";
  width: 0;
  margin-left: 0;
  height: 1.28571428em;
  border: 0 solid transparent;
  border-radius: 1.28571428em;
  transition: width 0.25s, border 0.25s, margin-left 0.25s;
}

.btn-loader[type=submit]:disabled::after {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite;
  width: 1.28571428em;
  border: 0.1428571429em solid #fff;
  border-right-color: transparent;
  margin-left: 0.75em;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.w-100 {
  justify-content: center;
  width: 100%;
}

.filter-button {
  text-align: left;
}
.filter-button svg {
  font-size: 1.375rem;
}
.filter-button + .filter-button {
  margin-left: 0.5rem;
}

.btn-primary.ideal {
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
}
.btn-primary.ideal:before {
  content: "";
  background: url("/assets/images/ideal-2.png") no-repeat center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
  background-size: contain;
  margin-right: 0.25rem;
}

.icon-button {
  padding: 0.5rem;
  -webkit-tap-highlight-color: transparent;
}

/*
    Input fields
*/
input[type=email],
input[type=password],
input[type=text],
input[type=number],
input[type=tel],
input[type=date],
input[type=datetime-local],
input.form-search,
select,
.form-item select,
textarea,
.form-item textarea {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-family: "Rajdhani", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-black);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-white);
  border-radius: 0.3rem;
  min-height: 3.25rem;
  background-color: var(--color-white);
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.7222em;
  transition: all 0.2s ease-in-out;
}
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
input[type=date]::placeholder,
input[type=datetime-local]::placeholder,
input.form-search::placeholder,
select::placeholder,
.form-item select::placeholder,
textarea::placeholder,
.form-item textarea::placeholder {
  color: #AAAAAA;
  opacity: 1;
}
input[type=email]:focus, input[type=email].error:focus, input[type=email].valid:focus,
input[type=password]:focus,
input[type=password].error:focus,
input[type=password].valid:focus,
input[type=text]:focus,
input[type=text].error:focus,
input[type=text].valid:focus,
input[type=number]:focus,
input[type=number].error:focus,
input[type=number].valid:focus,
input[type=tel]:focus,
input[type=tel].error:focus,
input[type=tel].valid:focus,
input[type=date]:focus,
input[type=date].error:focus,
input[type=date].valid:focus,
input[type=datetime-local]:focus,
input[type=datetime-local].error:focus,
input[type=datetime-local].valid:focus,
input.form-search:focus,
input.form-search.error:focus,
input.form-search.valid:focus,
select:focus,
select.error:focus,
select.valid:focus,
.form-item select:focus,
.form-item select.error:focus,
.form-item select.valid:focus,
textarea:focus,
textarea.error:focus,
textarea.valid:focus,
.form-item textarea:focus,
.form-item textarea.error:focus,
.form-item textarea.valid:focus {
  border-color: hsl(var(--color-black-h), var(--color-black-s), calc(var(--color-black-l) * 0));
  box-shadow: 0 2px 0 hsl(var(--color-black-h), var(--color-black-s), calc(var(--color-black-l) * 0));
}

input[type=date] {
  padding-inline-end: 1rem;
  background-image: none !important;
  color: initial;
  display: flex;
}
input[type=range] {
  accent-color: var(--color-primary);
}

/*
    Fieldsets/Legends/Labels
*/
fieldset:not(.radios--wrapper):not(.webform-select-other--wrapper):not(.webform-radios-other--wrapper):not(.webform-checkboxes-other--wrapper) {
  padding: 0;
  margin: 0;
  border: none;
}
fieldset:not(.radios--wrapper):not(.webform-select-other--wrapper):not(.webform-radios-other--wrapper):not(.webform-checkboxes-other--wrapper) > legend {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.38;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  fieldset:not(.radios--wrapper):not(.webform-select-other--wrapper):not(.webform-radios-other--wrapper):not(.webform-checkboxes-other--wrapper) > legend {
    font-size: 1.625rem;
  }
}

label {
  display: block;
  font-weight: 700;
}

/*
    Select
*/
select {
  appearance: none;
}

fieldset.webform-select-other--wrapper {
  isolation: isolate;
}
fieldset.webform-select-other--wrapper:focus-within select,
fieldset.webform-select-other--wrapper:focus-within select.valid,
fieldset.webform-select-other--wrapper:focus-within select.error,
fieldset.webform-select-other--wrapper:focus-within input,
fieldset.webform-select-other--wrapper:focus-within input.valid,
fieldset.webform-select-other--wrapper:focus-within input.error {
  border-color: hsl(var(--color-black-h), var(--color-black-s), calc(var(--color-black-l) * 0));
  border-bottom: 1px solid hsl(var(--color-black-h), var(--color-black-s), calc(var(--color-black-l) * 0));
}
fieldset.webform-select-other--wrapper select,
fieldset.webform-select-other--wrapper select.valid,
fieldset.webform-select-other--wrapper select.error {
  border-color: var(--color-white);
}
fieldset.webform-select-other--wrapper .form-item {
  margin-bottom: 0;
}
fieldset.webform-select-other--wrapper .form-type-select {
  position: relative;
  z-index: 1;
}
fieldset.webform-select-other--wrapper .webform-select-other-input {
  margin-top: -0.3rem;
}
fieldset.webform-select-other--wrapper .webform-select-other-input input {
  border-radius: 0 0 0.3rem 0.3rem;
}

/*
    Form validation
*/
input[type=email].error,
input[type=password].error,
input[type=text].error,
input[type=number].error,
input[type=tel].error,
input[type=date].error,
input[type=datetime-local].error,
input.form-search.error,
select.error,
.form-item select.error,
textarea.error,
.form-item textarea.error {
  border-width: 1px;
  border-color: var(--color-white);
  border-bottom: 1px solid var(--color-danger);
}
input[type=email].valid,
input[type=password].valid,
input[type=text].valid,
input[type=number].valid,
input[type=tel].valid,
input[type=date].valid,
input[type=datetime-local].valid,
input.form-search.valid,
select.valid,
.form-item select.valid,
textarea.valid,
.form-item textarea.valid {
  border-width: 1px;
  border-color: var(--color-white);
  border-bottom: 1px solid var(--color-valid);
}

input.webform-counter-warning,
input.form-text.webform-counter-warning,
textarea.webform-counter-warning,
textarea.form-textarea.webform-counter-warning {
  background-repeat: no-repeat;
  background-color: var(--color-white);
  background-position: right 1rem center;
}

label.error {
  display: inline-flex;
  align-items: center;
  color: var(--color-danger);
  font-size: 0.777em;
  font-weight: 500;
  margin-top: 0.222em;
  margin-left: 0.55em;
}
label.error:before {
  content: "";
  display: inline-block;
  background-color: currentColor;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin-right: 1ch;
}

.input-password {
  position: relative;
}
.input-password input[type=password] {
  padding-right: 1rem;
}
.input-password button {
  font-size: 1rem;
  color: var(--color-black);
  position: absolute;
  right: 1rem;
  top: 2.5rem;
  z-index: 50;
  -webkit-tap-highlight-color: transparent;
}

/*
    Form items
*/
.form-composite > .fieldset-wrapper > .description,
.form-item .description {
  margin-top: 0.222em;
  font-size: 0.888em;
  color: #AAAAAA;
}

.form-text {
  margin-top: 0;
}

.form-required:after {
  content: "*";
  background: none;
  color: inherit;
  font-size: 0.75em;
}

.form-item,
.form-actions {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.form-actions {
  margin-top: 0;
}

.form-disabled label {
  color: var(--color-white);
}

/*
    Text counter
    - Drupal: Activate counter in textarea form field
*/
.text-count-wrapper,
.text-count-wrapper.webform-counter-warning {
  margin-top: 0.222em;
  font-size: 0.888em;
  text-align: end;
  font-weight: 500;
  color: #AAAAAA;
}

/*
    Checkbox
    - Use the 'description' field to create a label with links.
*/
.form-type-checkbox {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: calc(1.25rem + 0.5em);
}
.form-type-checkbox input[type=checkbox] {
  -webkit-appearance: none;
  position: absolute;
  top: 0.25em;
  left: 0;
  background-color: transparent;
  border: 2px solid var(--color-white);
  border-radius: 3px;
  display: inline-block;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.form-type-checkbox input[type=checkbox]:focus {
  border-color: var(--color-primary);
}
.form-type-checkbox input[type=checkbox]:active, .form-type-checkbox input[type=checkbox]:checked:active {
  border-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
.form-type-checkbox input[type=checkbox]:active:after, .form-type-checkbox input[type=checkbox]:checked:active:after {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
.form-type-checkbox input[type=checkbox]:checked {
  border-color: var(--color-primary);
}
.form-type-checkbox input[type=checkbox]:checked:after {
  content: "";
  content: "";
  mask: url("/assets/icons/check.svg") no-repeat 50% 50%;
  mask-size: contain;
  mask-position: right;
  display: inline-block;
  height: 1em;
  width: 1em;
  background-color: var(--color-primary);
  transition: all 0.1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.form-type-checkbox label {
  font-weight: normal;
}
.form-type-checkbox label.error {
  order: 999;
}
.form-type-checkbox label,
.form-type-checkbox .description {
  margin: 0;
}
.form-type-checkbox .description {
  color: inherit;
  font-size: 1em;
  margin-top: 0.15em;
}

/*
    Radio buttons
    - Use the 'description' field to create a label with links.
*/
.form-type-radio {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: calc(1.25rem + 0.5em);
}
.form-type-radio input[type=radio] {
  -webkit-appearance: none;
  position: absolute;
  top: 0.15em;
  left: 0;
  display: inline-block;
  background-color: transparent;
  border: 2px solid var(--color-black);
  border-radius: 100%;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.form-type-radio input[type=radio]:checked {
  border-color: var(--color-primary);
}
.form-type-radio input[type=radio]:checked:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--color-primary);
  border-radius: 100%;
  width: calc(1.25rem - 10px);
  min-width: calc(1.25rem - 10px);
  height: calc(1.25rem - 10px);
}
.form-type-radio input[type=radio]:checked + label.option {
  color: var(--color-primary);
}
.form-type-radio label.option {
  font-weight: 700;
}
.form-type-radio label.error {
  order: 999;
}
.form-type-radio label,
.form-type-radio .description {
  margin: 0;
}

/*
    Range slider
    - Use the Drupal range slider module to achieve full control over styling
*/
.form-type-range-slider:focus-within .rangeslider {
  box-shadow: 0 0 0 0.25rem hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}
.form-type-range-slider .rangeslider {
  background-color: #AAAAAA;
  box-shadow: none;
}
.form-type-range-slider .rangeslider:active {
  box-shadow: 0 0 0 0.25rem hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}
.form-type-range-slider .rangeslider:active .rangeslider__handle {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
.form-type-range-slider .rangeslider--horizontal {
  height: 4px;
}
.form-type-range-slider .rangeslider--horizontal .rangeslider__handle {
  top: -11px;
}
.form-type-range-slider .rangeslider__fill {
  background-color: var(--color-primary);
  box-shadow: none;
}
.form-type-range-slider .rangeslider__handle {
  box-shadow: none !important;
  border: none;
  background-color: var(--color-primary);
  background-image: none !important;
  width: 26px;
  height: 26px;
}
.form-type-range-slider .rangeslider__handle:after {
  content: none;
}

/*
    Switch/Toggle
    - Add class form-switch to checkbox form field
*/
.form-switch {
  padding-left: 4.125em;
}
.form-switch label:not(.error) {
  color: inherit !important;
}
.form-switch input[type=checkbox] {
  appearance: none;
  width: 3.625em;
  height: 2em;
  margin-top: -0.25em;
  margin-left: -4.125em;
  vertical-align: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  background-color: var(--color-white);
  border: var(--color-white);
  border-radius: 3.625em;
  color-adjust: exact;
  top: 0;
  left: unset;
  transition: all 0.3s ease-in-out;
}
.form-switch input[type=checkbox]:active {
  background-color: hsl(var(--color-black-h), var(--color-black-s), calc(var(--color-black-l) * 0));
}
.form-switch input[type=checkbox]:checked:active {
  background-color: hsl(var(--color-valid-h), var(--color-valid-s), calc(var(--color-valid-l) * 0.5));
}
.form-switch input[type=checkbox]:focus {
  border-color: #86b7fe;
  outline: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  box-shadow: 0 0 0 0.25rem hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
}
.form-switch input[type=checkbox]:checked {
  background-color: var(--color-valid);
  background-position: right center;
  border-color: var(--color-valid);
}
.form-switch input[type=checkbox]:checked:after {
  content: none;
}
.form-switch input[type=checkbox]:indeterminate {
  background-color: var(--color-valid);
  border-color: #0d6efd;
}
.form-switch input[type=checkbox]:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

/*
    Submit button
*/
button.form-submit {
  display: inline-flex;
  position: relative;
  color: var(--color-white);
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  line-height: 1.272;
  padding: 0.67em 1.333em;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  text-decoration: none;
  outline-offset: 4px;
  background: var(--color-primary);
}
button.form-submit:not(:disabled):hover, button.form-submit:not(:disabled):focus {
  color: var(--color-white);
}
button.form-submit:disabled {
  background: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.4);
}
button.form-submit > svg {
  margin-right: 0.25rem;
}
button.form-submit:not(:disabled):hover, button.form-submit:not(:disabled):focus {
  border-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * var(--color-primary-l-darker)));
}
button.form-submit:not(:disabled):focus-visible {
  outline-color: var(--color-primary);
}

/*
    Webform flex
*/
.webform-flex--0 {
  flex: 0 0 min-content;
}

.formkit-messages {
  margin-top: 0.5rem;
  color: var(--color-warning);
}

form > .formkit-messages {
  margin-bottom: 0.5rem;
}

.form-buttons, .form-type-checkbox .formkit-outer {
  order: 999;
}

.card {
  background: var(--color-background-light);
  border-radius: 0.625rem;
  padding: 1rem;
}
.card img, .card svg.placeholder {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 0.625rem;
  object-fit: cover;
}
.card .virtual-h1 {
  line-height: 1;
}
.card.background-primary {
  background-color: var(--color-primary);
}
.card.bordered {
  background: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.4);
  border: 1px solid var(--color-primary);
}

.go-back {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.go-back:before {
  content: "";
  background: url("/assets/icons/chevron-left.svg") no-repeat center right;
  background-size: contain;
  width: 1.25rem;
  height: 1.25rem;
}

.read-more {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.read-more:after {
  content: "";
  background: url("/assets/icons/chevron-right.svg") no-repeat center left;
  background-size: contain;
  width: 1.25rem;
  height: 1.25rem;
}

.shape-button {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
}
.shape-button:after {
  content: "";
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: 1.75rem;
  height: calc( 1.75rem * 1.1547);
  background-color: var(--color-primary);
  display: block;
  margin-left: 0.5rem;
}
.shape-button:before {
  content: "";
  background: url("/assets/icons/chevron-right.svg") no-repeat center center;
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0.375rem;
  top: 0.5rem;
  z-index: 1;
}

:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 0.4rem;
  --popper-theme-padding: 1rem;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  max-width: 80%;
}

.toggle {
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.toggle + div {
  border: 0 !important;
}
.toggle:before {
  content: "";
  background: url("/assets/icons/chevron-down.svg") no-repeat center;
  background-size: contain;
  width: 1.5rem;
  height: 1rem;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
.toggle.open:before {
  transform: rotate(180deg);
}

.discount-form {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.discount-form input {
  border: 1px solid green;
  border-radius: 0.3rem 0 0 0.3rem;
}
.discount-form button {
  padding: 0 1rem;
  background: var(--color-white);
  color: var(--color-black);
  border: 1px solid green;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 0 0.3rem 0.3rem 0;
  border-left: 0;
  font-weight: 500;
}

.embed-responsive-9by16 {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive-9by16:before {
  padding-top: 177.8%;
  display: block;
  content: "";
}
.embed-responsive-9by16 .embed-responsive-item {
  border: 0;
}
.embed-responsive-9by16 .embed-responsive-item iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.add-to-home {
  position: relative;
}
.add-to-home .close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url("/assets/icons/close.svg") no-repeat center;
  background-size: cover;
}
.add-to-home .add-button {
  display: flex;
  align-items: center;
  font-weight: 700;
  text-decoration: none;
}
.add-to-home .add-button:before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: var(--color-primary);
  mask-image: url("/assets/icons/add.svg");
  mask-position: center;
  mask-size: contain;
  margin-right: 0.5rem;
}

html, body {
  overflow-x: hidden;
  background-color: #0D2215;
}

body {
  min-height: 100vh;
  background: linear-gradient(180deg, #0D2215 0%, var(--color-black) 99.78%) fixed;
  padding-bottom: calc(6rem + env(safe-area-inset-bottom));
}
body:after {
  content: "";
  position: fixed;
  bottom: 0;
  height: 70%;
  width: 50%;
  left: 65%;
  background: url("/assets/images/polygon.svg") no-repeat center right;
  z-index: 0;
  pointer-events: none;
}
body > div {
  position: relative;
  z-index: 10;
}

hr {
  border-color: var(--color-primary);
}

#app > div > .container {
  max-width: 720px;
}

nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: var(--color-background-light);
  font-weight: bold;
  line-height: 1;
  font-size: 0.75rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
  z-index: 100;
  height: calc(4rem + env(safe-area-inset-bottom));
  display: flex;
  padding-bottom: env(safe-area-inset-bottom);
}
nav ul.menu {
  margin: 0;
  width: 100vw;
  display: flex;
}
nav ul.menu li {
  list-style: none;
  padding: 0;
  flex: 3;
  display: flex;
  flex-direction: column;
}
nav ul.menu li a, nav ul.menu li button {
  text-decoration: none;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625em 0;
  height: 100%;
  justify-content: end;
}
nav ul.menu li a svg, nav ul.menu li button svg {
  margin-bottom: 0.5em;
  width: 1.75rem;
  height: 1.72rem;
}
nav ul.menu li a:hover, nav ul.menu li a:focus, nav ul.menu li a.is-active, nav ul.menu li button:hover, nav ul.menu li button:focus, nav ul.menu li button.is-active {
  color: var(--color-primary);
}

header {
  height: 30vh;
  position: relative;
  z-index: 1;
  mask-image: linear-gradient(to top, transparent 0%, black 15%);
}
header > img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
header:after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgb(0, 0, 0) 100%);
  height: 40vh;
}
header ~ .container {
  position: relative;
  z-index: 1;
}
header.no-overflow {
  mask-image: none;
  height: auto;
}
header.no-overflow > img {
  height: auto;
  aspect-ratio: 16/9;
  max-height: 30vh;
}
header.no-overflow:after {
  content: none;
}
@media (min-width: 992px) {
  header > img {
    height: 60vh;
  }
}

.auth-container .logo {
  display: flex;
  justify-content: center;
  margin: 4rem 0 2rem;
}
.auth-container .logo svg {
  width: 25%;
}

body.auth {
  background: url("/assets/images/bg-auth.jpg") no-repeat center bottom fixed;
  background-size: cover;
}
body.auth:after {
  content: none;
}

.reservation-overlay {
  --hexagon-size: 25vw;
  background: var(--color-background);
  position: fixed;
  inset: 0;
  z-index: 150;
  overflow: auto;
  bottom: 0rem;
  padding-bottom: calc(9rem + env(safe-area-inset-bottom));
}
@media (min-width: 768px) {
  .reservation-overlay {
    --hexagon-size: 15vw;
  }
}
@media (min-width: 992px) {
  .reservation-overlay {
    --hexagon-size: 10vw;
  }
}
.reservation-overlay .action-bar {
  background: var(--color-black-light);
  position: fixed;
  bottom: 0rem;
  left: 0;
  width: 100vw;
  height: calc(7rem + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  align-items: end;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.85);
}
.reservation-overlay .action-bar .icon-text {
  white-space: nowrap;
}
.reservation-overlay.step-summary {
  padding-bottom: calc(6rem + env(safe-area-inset-bottom));
}
.reservation-overlay.step-summary .action-bar {
  height: calc(4rem + env(safe-area-inset-bottom));
}
.reservation-overlay.step-summary .container {
  max-width: 750px;
}
.reservation-overlay.step-authentication {
  padding-bottom: calc(2rem + env(safe-area-inset-bottom));
}
.reservation-overlay .border {
  border: 1px solid var(--color-primary);
  border-radius: 0.625rem;
}
.reservation-overlay .image-shape {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: calc(var(--hexagon-size) + 2rem);
  height: calc( calc(var(--hexagon-size) + 2rem) * 1.1547);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  position: relative;
}
.reservation-overlay .image-shape > img, .reservation-overlay .image-shape > svg {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: var(--hexagon-size);
  height: calc( var(--hexagon-size) * 1.1547);
  object-fit: cover;
}
.reservation-overlay .image-shape:before {
  content: "";
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: calc(var(--hexagon-size) + 1rem);
  height: calc( calc(var(--hexagon-size) + 1rem) * 1.1547);
  background: var(--color-background-light);
  position: absolute;
}
.reservation-overlay .reservation-summary {
  position: relative;
  padding: calc(var(--hexagon-size) * 0.85) 1rem 1rem;
  margin-top: var(--hexagon-size);
}
.reservation-overlay .reservation-summary .image-shape {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: calc(var(--hexagon-size) * -0.75);
}
.reservation-overlay .reservation-summary .price {
  width: 25%;
}

.room {
  padding-bottom: calc(9rem + env(safe-area-inset-bottom));
}
.room footer {
  position: fixed;
  bottom: calc(4rem + env(safe-area-inset-bottom));
  background: var(--color-black-light);
  left: 0;
  width: 100vw;
  height: 4rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
  justify-content: center;
  z-index: 50;
}

.contact-buttons .hexagon {
  text-align: center;
  text-decoration: none;
}
.contact-buttons .hexagon span {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: 2.5rem;
  height: calc( 2.5rem * 1.1547);
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem;
}
.contact-buttons .hexagon span > svg {
  width: 1rem;
}

.faq-category > button {
  width: 100%;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: left;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  margin: 1rem 0 0;
}
.faq-category > button:after {
  content: "";
  width: 1.5rem;
  height: 1rem;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  background-color: var(--color-primary);
  mask-image: url("/assets/icons/chevron-down.svg");
  mask-position: center;
}
.faq-category.open > button:after {
  transform: rotate(180deg);
}
.faq-category .items-wrapper {
  overflow: hidden;
  height: 0;
  transition: all 0.2s ease-in-out;
}
.faq-category .items-wrapper .items {
  padding: 1rem 2rem;
}
.faq-category .item {
  margin-bottom: 0.5rem;
}
.faq-category .item button {
  font-size: 1rem;
  text-decoration: none;
  font-weight: 700;
  position: relative;
  text-align: left;
}
.faq-category .item button:after {
  content: "";
  width: 1.5rem;
  height: 1rem;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  background-color: var(--color-primary);
  mask-image: url("/assets/icons/chevron-right.svg");
  mask-position: center;
  position: absolute;
  left: -1.5rem;
  top: 0.125rem;
}
.faq-category .item.open button:after {
  transform: rotate(90deg);
}
.faq-category .answer-wrapper {
  overflow: hidden;
  height: 0;
  transition: all 0.2s ease-in-out;
}
.faq-category .answer-wrapper .answer {
  margin-bottom: 1rem;
}