.add-to-home{
    position: relative;

    .close{
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        width: 1.25rem;
        height: 1.25rem;
        background: url('/assets/icons/close.svg') no-repeat center;
        background-size: cover;
    }

    .add-button{
        display: flex;
        align-items: center;
        font-weight: $font-weight-bold;
        text-decoration: none;

        &:before{
            content: "";
            width: 1rem;
            height: 1rem;
            background-color: var(--color-primary);
            mask-image: url('/assets/icons/add.svg');
            mask-position: center;
            mask-size: contain;
            margin-right: 0.5rem;
        }
    }
}
