.faq-category {
    > button {
        width: 100%;
        font-weight: $font-weight-bold;
        font-size: 1.5rem;
        text-align: left;
        border-bottom: 1px solid var(--color-primary);
        padding-bottom: 0.75rem;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        align-items: center;
        margin: 1rem 0 0;

        &:after {
            content: "";
            width: 1.5rem;
            height: 1rem;
            display: inline-block;
            transition: $transition-base;
            background-color: var(--color-primary);
            mask-image: url('/assets/icons/chevron-down.svg');
            mask-position: center;
        }
    }

    &.open {
        > button:after {
            transform: rotate(180deg);
        }

    }

    .items-wrapper{
        overflow: hidden;
        height: 0;
        transition: $transition-base ;

        .items{
            padding: 1rem 2rem;
        }
    }

    .item{
        margin-bottom: 0.5rem;

        button{
            font-size: 1rem;
            text-decoration: none;
            font-weight: $font-weight-bold;
            position: relative;
            text-align: left;

            &:after {
                content: "";
                width: 1.5rem;
                height: 1rem;
                display: inline-block;
                transition: $transition-base;
                background-color: var(--color-primary);
                mask-image: url('/assets/icons/chevron-right.svg');
                mask-position: center;
                position: absolute;
                left: -1.5rem;
                top: 0.125rem;
            }
        }

        &.open{
            button{
                &:after{
                    transform: rotate(90deg);
                }
            }
        }
    }

    .answer-wrapper{
        overflow: hidden;
        height: 0;
        transition: $transition-base ;

        .answer{
            margin-bottom: 1rem;
        }
    }
}
