
body {
    font-weight: $font-weight-base;
    font-size: 1rem;
    color: var(--color-text);
    font-family: $font-family-base;
    line-height: $line-height-base;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-osx-font-smoothing: grayscale;
}

.virtual-h0 {
    @include h1;
    font-size: 2.75rem;
}

h1,
.virtual-h1 {
    @include h1;
    font-size: 1.75rem;
    margin-bottom: 0.57em;
}

h2,
.virtual-h2 {
    @include h2;
    margin-bottom: 1em;
}

h3,
.virtual-h3 {
    @include h3;
    margin-bottom: 1em;
}

h4,
.virtual-h4 {
    @include h4;
    margin-bottom: 0.5em;
}

.subtitle {
    @include subtitle;
    margin-bottom: 1em;
}

.intro-text {
    @include intro-text;
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

//ul {
//    list-style: none;
//}

a, button {
    color: var(--color-text);
    text-decoration: underline;
    transition: $transition-hover;

    &:hover,
    &:focus {
        color: var(--color-primary-darker);
    }
}

blockquote {
    @include h1;
    font-weight: $font-weight-bold;
    margin: 0 0 0.57em 0;

    p {
        margin-bottom: 0;

        &:before {
            content: open-quote;
            display: inline;
        }

        &:after {
            content: close-quote;
            display: inline;
        }
    }
}


.text-right {
    text-align: right;
}

.text-light {
    color: alpha(var(--color-text), 0.5);
}

.font-weight-normal {
    font-weight: $font-weight-normal;
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-bold {
    font-weight: $font-weight-bold;
}

.color-primary {
    color: var(--color-primary);
}

.color-secondary {
    color: var(--color-seconday);
}

.text-slightly-smaller {
    font-size: 0.875rem;
}

.text-slightly-larger {
    font-size: 1.125rem;
}

.icon-text {
    display: flex;
    flex-direction: row;

    > svg {
        margin-top: 0.125rem;
        margin-right: 0.5rem;
    }

    &.small {
        > svg {
            margin-right: 0.125rem;
        }
    }
}

.text-icon {
    display: flex;
    flex-direction: row;

    > svg {
        margin-top: 0.125rem;
        margin-left: 0.5rem;
    }

    &.small {
        > svg {
            margin-left: 0.125rem;
        }
    }
}

ol {
    padding-left: 1rem;
    counter-reset: item;
}

ol li {
    display: block;
    position: relative;

    &.no-before:before{
        //display: none;
        visibility: hidden;
    }
}

ol li:before {
    content: counters(item, ".");
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 10px; /* space between number and text */
}
