/*
    Input fields
*/

// Input
input[type=email],
input[type=password],
input[type=text],
input[type=number],
input[type=tel],
input[type=date],
input[type=datetime-local],
input.form-search,
select,
.form-item select,
textarea,
.form-item textarea {
    display: block;
    width: 100%;
    height: auto;
    padding: $input-padding-y $input-padding-x $input-padding-y $input-padding-x;
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    border-width: $input-border-width;
    border-style: solid;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    min-height: $input-height;
    background-color: $input-bg;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-position: right $input-padding-x center;
    background-size: 0.7222em;
    transition: all 0.2s ease-in-out;

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1; // Override Firefox's unusual default opacity
    }

    &:focus,
    &.error:focus,
    &.valid:focus {
        border-color: $input-border-color-focus;
        box-shadow: 0 2px 0 $input-border-color-focus;
    }
}

input[type=date] {
    padding-inline-end: $input-padding-x;
    background-image: none !important;
    color: initial;
    display: flex;

    &::-webkit-calendar-picker-indicator {
        //         background-image: url("../images/form/calendar.svg");
    }
}

input[type=range] {
    accent-color: var(--color-primary);
}

/*
    Fieldsets/Legends/Labels
*/

fieldset:not(.radios--wrapper):not(.webform-select-other--wrapper):not(.webform-radios-other--wrapper):not(.webform-checkboxes-other--wrapper) {
    padding: 0;
    margin: 0;
    border: none;

    > legend {
        @include h3;
        margin-bottom: 2rem;
    }
}

label {
    display: block;
    font-weight: $font-weight-bold;
}

/*
    Select
*/

select {
    appearance: none;
    //     background-image: url("../images/form/select.svg") !important;
}

fieldset.webform-select-other--wrapper {
    isolation: isolate;

    &:focus-within {
        select,
        select.valid,
        select.error,
        input,
        input.valid,
        input.error {
            border-color: $input-border-color-focus;
            border-bottom: $input-border-width solid $input-border-color-focus;
        }
    }

    select,
    select.valid,
    select.error {
        border-color: $input-border-color;
    }

    .form-item {
        margin-bottom: 0;
    }

    .form-type-select {
        position: relative;
        z-index: 1;
    }

    .webform-select-other-input {
        margin-top: -$input-border-radius;

        input {
            border-radius: 0 0 $input-border-radius $input-border-radius;
        }
    }
}

/*
    Form validation
*/

input[type=email],
input[type=password],
input[type=text],
input[type=number],
input[type=tel],
input[type=date],
input[type=datetime-local],
input.form-search,
select,
.form-item select,
textarea,
.form-item textarea {
    &.error {
        border-width: $input-border-width;
        border-color: $input-border-color;
        border-bottom: $input-border-width solid var(--color-danger);
    }

    &.valid {
        border-width: $input-border-width;
        border-color: $input-border-color;
        border-bottom: $input-border-width solid var(--color-valid);
        //         background-image: url("../images/form/valid.svg");
    }
}

input.webform-counter-warning,
input.form-text.webform-counter-warning,
textarea.webform-counter-warning,
textarea.form-textarea.webform-counter-warning {
    background-repeat: no-repeat;
    background-color: $input-bg;
    background-position: right $input-padding-x center;
}


label {
    &.error {
        display: inline-flex;
        align-items: center;
        color: var(--color-danger);
        font-size: 0.777em;
        font-weight: $font-weight-base;
        margin-top: 0.222em;
        margin-left: 0.55em;

        &:before {
            content: '';
            display: inline-block;
            background-color: currentColor;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            margin-right: 1ch;
        }
    }
}

.input-password {
    position: relative;

    input[type=password] {
        padding-right: 1rem;
    }

    button {
        font-size: 1rem;
        color: var(--color-black);
        position: absolute;
        right: 1rem;
        top: 2.5rem;
        z-index: 50;
        -webkit-tap-highlight-color: transparent;
    }

}

/*
    Form items
*/

.form-composite > .fieldset-wrapper > .description,
.form-item .description {
    margin-top: 0.222em;
    font-size: 0.888em;
    color: $input-placeholder-color;
}

.form-text {
    margin-top: 0;
}

// Form required *
.form-required:after {
    content: '*';
    background: none;
    color: inherit;
    font-size: 0.75em;
}

.form-item,
.form-actions {
    margin-top: 0;
    margin-bottom: 1.5em;
}

.form-actions {
    margin-top: 0;
}

.form-disabled {
    label {
        color: $input-border-color;
    }
}

/*
    Text counter
    - Drupal: Activate counter in textarea form field
*/

.text-count-wrapper,
.text-count-wrapper.webform-counter-warning {
    margin-top: 0.222em;
    font-size: 0.888em;
    text-align: end;
    font-weight: $font-weight-base;
    color: $input-placeholder-color;
}

/*
    Checkbox
    - Use the 'description' field to create a label with links.
*/

.form-type-checkbox {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: $checkbox-padding;

    input[type="checkbox"] {
        -webkit-appearance: none;
        position: absolute;
        top: 0.25em;
        left: 0;
        background-color: transparent;
        border: 2px solid var(--color-white);
        border-radius: 3px;
        display: inline-block;
        width: $checkbox-size;
        min-width: $checkbox-size;
        height: $checkbox-size;
        cursor: pointer;

        &:focus {
            border-color: var(--color-primary);
        }

        &:active,
        &:checked:active {
            border-color: lightness(var(--color-primary), var(--color-primary-l-darker));

            &:after {
                background-color: lightness(var(--color-primary), var(--color-primary-l-darker));
            }
        }

        &:checked {
            border-color: var(--color-primary);

            &:after {
                content: '';
                @include icon($icon-name: 'check', $icon-size: 1em);
                @include center-absolute;
            }
        }
    }

    label {
        font-weight: normal;

        &.error {
            order: 999;
        }
    }

    label,
    .description {
        margin: 0;
    }

    .description {
        color: inherit;
        font-size: 1em;
        margin-top: 0.15em;
    }
}

/*
    Radio buttons
    - Use the 'description' field to create a label with links.
*/

.form-type-radio {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: $radio-padding;

    input[type="radio"] {
        -webkit-appearance: none;
        position: absolute;
        top: 0.15em;
        left: 0;
        display: inline-block;
        background-color: transparent;
        border: 2px solid $input-color;
        border-radius: 100%;
        width: $radio-size;
        min-width: $radio-size;
        height: $radio-size;
        cursor: pointer;

        &:checked {
            border-color: var(--color-primary);

            &:after {
                content: '';
                @include center-absolute;
                background-color: var(--color-primary);
                border-radius: 100%;
                width: calc(#{$radio-size} - 10px);
                min-width: calc(#{$radio-size} - 10px);
                height: calc(#{$radio-size} - 10px);
            }

            + label.option {
                color: var(--color-primary);
            }
        }
    }

    label {
        &.option {
            font-weight: $font-weight-bold;
        }

        &.error {
            order: 999;
        }
    }

    label,
    .description {
        margin: 0;
    }
}

/*
    Range slider
    - Use the Drupal range slider module to achieve full control over styling
*/

.form-type-range-slider {
    &:focus-within {
        .rangeslider {
            @include focus-box-shadow();
        }
    }

    .rangeslider {
        background-color: $input-placeholder-color;
        box-shadow: none;

        &:active {
            @include focus-box-shadow();

            .rangeslider__handle {
                background-color: lightness(var(--color-primary), var(--color-primary-l-darker));
            }
        }

        &--horizontal {
            height: 4px;

            .rangeslider__handle {
                top: -11px;
            }
        }

        &__fill {
            background-color: var(--color-primary);
            box-shadow: none;
        }

        &__handle {
            box-shadow: none !important;
            border: none;
            background-color: var(--color-primary);
            background-image: none !important;
            width: 26px;
            height: 26px;

            &:after {
                content: none;
            }
        }
    }
}

/*
    Switch/Toggle
    - Add class form-switch to checkbox form field
*/

.form-switch {
    padding-left: $form-switch-padding-start;

    label:not(.error) {
        color: inherit !important;
    }

    input[type=checkbox] {
        appearance: none;
        width: $form-switch-width;
        height: $form-check-input-width;
        margin-top: ($line-height-base - $form-check-input-width) * .5; // line-height minus check height
        margin-left: $form-switch-padding-start * -1;
        vertical-align: top;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: escape-svg($form-switch-bg-image);
        background-position: left center;
        background-color: $input-border-color;
        border: $input-border-color;
        border-radius: $form-switch-border-radius;
        color-adjust: exact; // Keep themed appearance for print
        top: 0;
        left: unset;
        transition: all 0.3s ease-in-out;

        &:active {
            background-color: $input-border-color-focus;
        }

        &:checked:active {
            background-color: lightness($form-check-input-checked-bg-color, 0.5);
        }

        &:focus {
            border-color: $form-check-input-focus-border;
            outline: 0;
            background-image: escape-svg($form-switch-bg-image);
            @include focus-box-shadow();
        }

        &:checked {
            background-color: $form-check-input-checked-bg-color;
            background-position: $form-switch-checked-bg-position;
            border-color: $form-check-input-checked-border-color;

            &:after {
                content: none;
            }
        }

        &:indeterminate {
            background-color: $form-check-input-checked-bg-color;
            border-color: $form-check-input-indeterminate-border-color;
        }

        &:disabled {
            pointer-events: none;
            filter: none;
            opacity: $form-check-input-disabled-opacity;
        }
    }
}

/*
    Submit button
*/

button.form-submit {
    @include btn-primary;
}

/*
    Webform flex
*/

.webform-flex {
    &--0 {
        flex: 0 0 min-content;
    }
}

.formkit-messages {
    margin-top: 0.5rem;
    color: var(--color-warning);
}

form > .formkit-messages {
    margin-bottom: 0.5rem;
}

.form-buttons, .form-type-checkbox .formkit-outer {
    order: 999;
}
