.reservation-overlay {
    --hexagon-size: 25vw;

    background: var(--color-background);
    position: fixed;
    inset: 0;
    z-index: 150;
    overflow: auto;
    bottom: 0rem;
    padding-bottom: calc(9rem + env(safe-area-inset-bottom));

    @include media-breakpoint-up(md) {
        --hexagon-size: 15vw;
    }

    @include media-breakpoint-up(lg) {
        --hexagon-size: 10vw;
    }

    .action-bar {
        background: var(--color-black-light);
        position: fixed;
        bottom: 0rem;
        left: 0;
        width: 100vw;
        height: calc(7rem + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
        display: flex;
        align-items: end;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.85);

        .icon-text{
            white-space: nowrap;
        }
    }

    &.step-summary {
        padding-bottom: calc(6rem + env(safe-area-inset-bottom));

        .action-bar {
            height: calc(4rem + env(safe-area-inset-bottom));
        }

        .container {
            max-width: 750px;
        }
    }

    &.step-authentication {
        padding-bottom: calc(2rem + env(safe-area-inset-bottom));
    }

    .border {
        border: 1px solid var(--color-primary);
        border-radius: $border-radius;
    }

    .image-shape {
        @include make-hexagon(calc(var(--hexagon-size) + 2rem));

        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-background);
        position: relative;

        > img, > svg {
            @include make-hexagon(var(--hexagon-size));
            object-fit: cover;
        }

        &:before {
            content: "";
            @include make-hexagon(calc(var(--hexagon-size) + 1rem));
            background: var(--color-background-light);
            position: absolute;
        }
    }

    .reservation-summary {
        position: relative;
        padding: calc(var(--hexagon-size) * 0.85) 1rem 1rem;
        margin-top: var(--hexagon-size);

        .image-shape {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: calc(var(--hexagon-size) * -0.75);
        }

        .price {
            width: 25%;
        }
    }
}
