.go-back{
    display: flex;
    align-items: center;
    text-decoration: none;

    &:before{
        content: "";
        background: url('/assets/icons/chevron-left.svg') no-repeat center right;
        background-size: contain;
        width: 1.25rem;
        height: 1.25rem;
    }
}
.read-more{
    display: flex;
    align-items: center;
    text-decoration: none;

    &:after{
        content: "";
        background: url('/assets/icons/chevron-right.svg') no-repeat center left;
        background-size: contain;
        width: 1.25rem;
        height: 1.25rem;
    }
}

.shape-button{
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;

    &:after{
        content: "";
        @include make-hexagon(1.75rem);
        background-color: var(--color-primary);
        display: block;
        margin-left: 0.5rem;
    }

    &:before{
        content: "";
        background: url('/assets/icons/chevron-right.svg') no-repeat center center;
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.375rem;
        top: 0.5rem;
        z-index: 1;
    }
}
