.toggle{
    font-size: 1rem;
    display: flex;
    align-items: center;

    & +  div{
        border: 0 !important;
    }

    &:before{
        content: "";
        background: url('/assets/icons/chevron-down.svg') no-repeat center;
        background-size: contain;
        width: 1.5rem;
        height: 1rem;
        display: inline-block;
        transition: $transition-base;
    }

    &.open:before{
        transform: rotate(180deg);
    }
}

.discount-form{
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    input{
        border: 1px solid green;
        border-radius: 0.3rem 0 0 0.3rem;
    }

    button{
        padding: 0 1rem;
        background: var(--color-white);
        color: var(--color-black);
        border: 1px solid green;
        text-decoration: none;
        font-size: 1rem;
        border-radius: 0 0.3rem 0.3rem 0;
        border-left: 0;
        font-weight: $font-weight-medium;
    }
}
