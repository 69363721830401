.embed-responsive-9by16 {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &:before {
        padding-top: 177.8%;
        display: block;
        content: "";
    }

    .embed-responsive-item {
        border: 0;

        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}
