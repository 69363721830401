.contact-buttons {
    .hexagon {
        text-align: center;
        text-decoration: none;

        span {
            @include make-hexagon(2.5rem);
            background-color: var(--color-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 0.5rem;

            > svg{
                width: 1rem;
            }
        }
    }
}
