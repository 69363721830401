@mixin btn {
    display: inline-flex;
    position: relative;
    color: var(--color-white);
    font-size: 1rem;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    padding: $btn-padding-y $btn-padding-x;
    border-radius: $btn-border-radius;
    border: 1px solid transparent;
    transition: $btn-transition;
    text-align: center;
    text-decoration: none;
    outline-offset: 4px;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        color: var(--color-white);
    }

    &:disabled {
        background: alpha(var(--color-white), 0.4);
    }

    > svg {
        margin-right: 0.25rem;
    }
}

@mixin btn-primary {
    @include btn;
    background: var(--color-primary);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: lightness(var(--color-primary), var(--color-primary-l-darker));
    }

    &:not(:disabled):focus-visible {
        outline-color: var(--color-primary);
    }
}

@mixin btn-secondary {
    @include btn;
    background: var(--color-black-light);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: lightness(var(--color-black-light), var(--color-black-light-l-lighter));
    }

    &:not(:disabled):focus-visible {
        outline-color: var(--color-black-light);
    }
}

@mixin btn-outline {
    @include btn;
    color: var(--color-white);
    background: alpha(var(--color-primary), 0.4);
    border-color: var(--color-primary);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        color: var(--color-white);
        border-color: var(--color-primary);
    }

    &:not(:disabled):focus-visible {
        outline-color: var(--color-primary);
    }

    &:disabled{
        color: var(--color-grey);
        background: var(--color-black-light);
        border-color: var(--color-grey);
        opacity: 0.5;
    }
}


@mixin btn-outline-inactive {
    @include btn;
    color: var(--color-white);
    background: alpha(var(--color-black-light), 0.8);
    border-color: alpha(var(--color-white), 0.4);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-color: alpha(var(--color-white), 0.4);
    }

    &:not(:disabled):focus-visible {
        outline-color: var(--color-primary);
    }
}

@mixin text-link {
    color: var(--color-primary);
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    text-decoration: none;
    outline-offset: 4px;
    box-shadow: none;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        color: lightness(var(--color-primary), var(--color-primary-l-darker));

        &:after {
            background-color: currentColor;
        }
    }

    &:not(:disabled):focus-visible {
        outline: 1px dashed var(--color-primary);
    }

    &:after {
        @include icon($icon-name: 'arrow', $icon-size: 0.66em);
        margin-left: 0.66em;
    }
}

.btn-primary {
    @include btn-primary;

    &.outline {
        padding: calc(#{$btn-padding-y} - 1px) calc(#{$btn-padding-x} - 1px);
        background: transparent;
        border: 2px solid var(--color-primary);
    }
}

.btn-secondary {
    @include btn-secondary;
}

.btn-outline-inactive {
    @include btn-outline-inactive;
}

.btn-outline {
    @include btn-outline;
}

.text-link {
    @include text-link;
}

button, a {
    -webkit-tap-highlight-color: transparent;
}

.btn-loader::after {
    box-sizing: border-box;
    content: "";
    width: 0;
    margin-left: 0;
    height: 1.28571428em;
    border: 0 solid transparent;
    border-radius: 1.28571428em;
    transition: width .25s, border .25s, margin-left .25s;
}

.btn-loader[type=submit]:disabled::after {
    -webkit-animation: rotate .5s linear infinite;
    animation: rotate .5s linear infinite;
    width: 1.28571428em;
    border: 0.1428571429em solid #fff;
    border-right-color: transparent;
    margin-left: 0.75em;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.w-100 {
    justify-content: center;
    width: 100%;
}

.filter-button {
    text-align: left;

    svg {
        font-size: 1.375rem;
    }

    & + .filter-button {
        margin-left: 0.5rem;
    }
}

.btn-primary.ideal {
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 100%;

    &:before {
        content: "";
        background: url('/assets/images/ideal-2.png') no-repeat center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0;
        background-size: contain;
        margin-right: 0.25rem;
    }
}

.icon-button {
    padding: 0.5rem;
    -webkit-tap-highlight-color: transparent;
}

