// Responsive font-size
@mixin font-size-clamp($min-font-size, $value-font-size, $max-font-size) {
    font-size: $min-font-size; // Fallback

    @include media-breakpoint-up(sm) {
        font-size: $max-font-size; // Fallback
        font-size: clamp(#{$min-font-size}, calc(#{$value-font-size} + 1rem), #{$max-font-size});
    }
}

// Header styling
@mixin header-styling {
    font-family: $font-family-titles;
    font-weight: $font-weight-bold;
}


@mixin h1 {
    @include header-styling;
    @include font-size-clamp(2rem, 5.5vw, 3.5rem);
    line-height: 1.285;
}

@mixin h2 {
    @include header-styling;
    font-size: 1.625rem;
    line-height: 1.3;

    @include media-breakpoint-up(sm) {
        font-size: 2rem;
    }
}

@mixin h3 {
    @include header-styling;
    font-size: 1.25rem;
    line-height: 1.38;

    @include media-breakpoint-up(sm) {
        font-size: 1.625rem;
    }
}

@mixin h4 {
    @include header-styling;
    font-size: 1rem;
    line-height: 1.5;

    @include media-breakpoint-up(sm) {
        font-size: 1.125rem;
    }
}

@mixin subtitle {
    @include header-styling;
    @include h4;
    color: var(--color-primary);
}

@mixin intro-text {
    font-weight: $font-weight-bold;
}


