/*
    Accessibility
*/

// Hide element, show on screenreaders
.visually-hidden {
    @include visually-hidden;
}

/*
    Spacing
*/

.section-spacing-padding {
    @include section-spacing-padding;
}

.section-spacing-margin {
    @include section-spacing-margin;
}

/*
    Extra
*/

.w-100 {
    width: 100%;
}
